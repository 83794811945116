import axios from 'axios';
import React, { useState } from 'react';
import { TranactionsApi } from '../../api';
import Context from './index';

const TransactionProvider = (props) => {
    const [allData, setAllData] = useState([]);

    const [newTransactionId, setNewTransactionId] = useState(0);
    const [transactionStatus, setTransactionStatus] = useState('');

    const [clientTransactions, setClientTransactions] = useState(null);


    const getAllTransactionsData = async () => {
        try {
            await axios.get(TranactionsApi.getAll, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setAllData(resp.data);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    const getClientTransactions = async (id) => {
        setClientTransactions(null);
        try {
            await axios.get(TranactionsApi.getOfClient, {
                params: {
                    id: id,
                },
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setClientTransactions(resp.data);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };


    const setStatus = async (id, clientId, newStatus) => {
        try {
            await axios.post(TranactionsApi.set_status, {
                transaction_id: id,
                new_status: newStatus,
                client_id: clientId,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                let oldData = allData ?? [];
                let newData = oldData.map((item) => {

                    if (item.id == id) {
                        return {
                            ...item,
                            status: newStatus
                        }
                    }

                    return item;
                });

                setAllData([...newData]);

                let oldClientTransactionsData = clientTransactions ?? [];
                let clientTransactionsData = oldClientTransactionsData.map((item) => {

                    if (item.id == id) {
                        return {
                            ...item,
                            status: newStatus
                        }
                    }

                    return item;
                });

                setClientTransactions([...clientTransactionsData]);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };
    return (
        <Context.Provider
            value={{
                ...props,
                getAllTransactionsData,
                getClientTransactions,
                setStatus,
                allData,
                newTransactionId,
                transactionStatus,
                clientTransactions,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default TransactionProvider;