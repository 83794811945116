import axios from 'axios';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from "../Constant";

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import UserContext from '../_helper/User';
import { LoginApi } from "../api";

const Signin = ({ selected }) => {

  const { isCurrentUserManager } = useContext(UserContext);


  const [email, setEmail] = useState("manager");
  const [password, setPassword] = useState("manager");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    localStorage.removeItem('login');
    localStorage.removeItem('token');
    localStorage.removeItem('isManager');
  }, []);

  const loginAuth = async (e) => {
    e.preventDefault();

    const loginData = {
      username: email,
      password: password,
    };

    axios.post(`${LoginApi}`, loginData)
      .then(async (res) => {

        if (undefined === res.data.token) {
          toast.error("You enter wrong password or username!..");
          return;
        }
        const { token } = res.data;
        localStorage.setItem("login", JSON.stringify(true));
        localStorage.setItem('token', JSON.stringify(token));

        let isManager = await isCurrentUserManager();

        if (isManager) {
          localStorage.setItem("isManager", JSON.stringify(true));
          history(`${process.env.PUBLIC_URL}/dashboard`);
          toast.success("Successfully logged in!..");
        } else {
          toast.error("You must be manager to use this application.");
        }

      })
      .catch(err => {
        console.log(err);
        toast.error("You enter wrong password or username!..");
      })


    // setValue(man);
    // setName("Emay Walter");
    // if (email === "test@gmail.com" && password === "test123") {
    //   localStorage.setItem("login", JSON.stringify(true));
    //   history(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
    //   toast.success("Successfully logged in!..");
    // } else {
    //   toast.error("You enter wrong password or username!..");
    // }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>{"Sign In"}</H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} value={password} />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox d-none">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <a className="link d-none" href="#javascript">
                      {ForgotPassword}
                    </a>
                    <Btn attrBtn={{ color: "primary", className: "d-block w-100 mt-2", onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
                  </div>
                  {/* <OtherWay /> */}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
