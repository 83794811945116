import axios from 'axios';
import React, { useState } from 'react';
import { UserApi } from '../../api';
import Context from './index';

const UserProvider = (props) => {
    const [firstName, setFirstName] = useState('');
    const [isManager, setIsManager] = useState(null);
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [balanceMain, setBalanceMain] = useState(0);
    const [balanceAvailable, setBalanceAvailable] = useState(0);

    const getCurrentUserData = async () => {
        try {
            await axios.get(UserApi.getAll, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setFirstName(resp.data?.firstName ?? '');
                setLastName(resp.data?.lastName ?? '');
                setEmail(resp.data?.email ?? '');
                setPhone(resp.data?.phone ?? '');
                setUsername(resp.data?.username ?? '');
                setIsManager(resp.data?.isManager ?? false);
                setImageUrl(resp.data?.imageUrl ?? '');
                setBankName(resp.data?.bankName ?? '');
                setBankAccount(resp.data?.bankAccount ?? '');
                setBalanceMain(resp.data?.balanceMain ?? 0);
                setBalanceAvailable(resp.data?.balanceAvailable ?? 0);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    const isCurrentUserManager = async () => {
        try {
            let result = await axios.get(UserApi.getAll, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            });
            return result?.data?.isManager;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    }

    const updateCurrentUserBankData = async (bankName, bankAccount, callback = null) => {
        try {
            await axios.post(UserApi.updateBanking, {
                bankName: bankName,
                bankAccount: bankAccount,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setBankName(bankName ?? '');
                    setBankAccount(bankAccount ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserPersonalData = async (firstName, lastName, email, phone, callback = null) => {
        try {
            await axios.post(UserApi.updatePersonalData, {
                email: email,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setFirstName(firstName ?? '');
                    setLastName(lastName ?? '');
                    setEmail(email ?? '');
                    setPhone(phone ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserPassword = async (newPassword, callback = null) => {
        try {
            await axios.post(UserApi.updatePassword, {
                newPassword: newPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    console.log('password changed');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };


    const updateCurrentUserAvatar = async (file, callback = null) => {
        try {

            let formData = new FormData();
            formData.append("newAvatar", file);
            await axios.post(UserApi.updateAvatar, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setImageUrl(resp.data?.imageUrl ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const deleteCurrentUserAvatar = async (callback = null) => {
        try {
            await axios.delete(UserApi.deleteAvatar, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setImageUrl('');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    return (
        <Context.Provider
            value={{
                ...props,
                getCurrentUserData,
                isCurrentUserManager,
                updateCurrentUserBankData,
                updateCurrentUserPersonalData,
                updateCurrentUserPassword,
                updateCurrentUserAvatar,
                deleteCurrentUserAvatar,
                firstName,
                lastName,
                email,
                phone,
                username,
                isManager,
                imageUrl,
                bankName,
                bankAccount,
                balanceMain,
                balanceAvailable
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default UserProvider;