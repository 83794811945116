import React, { useContext, useEffect, useState } from 'react';
import { LogIn, User } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import man from '../../../assets/images/dashboard/profile.png';

import { ToolTip, Image, LI, P, UL } from '../../../AbstractElements';
import { Account, LogOut } from '../../../Constant';
import CustomizerContext from '../../../_helper/Customizer';
import UserContext from '../../../_helper/User';

const UserHeader = () => {
  const { getCurrentUserData, firstName, lastName } = useContext(UserContext);
  useEffect(() => {
    getCurrentUserData();
  }, []);

  const [basictooltip, setbasictooltip] = useState(false);
  const toggle = () => setbasictooltip(!basictooltip);

  const history = useNavigate();

  const Logout = () => {
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };
  return (

    <>
      <ToolTip
        attrToolTip={{
          innerClassName: 'bg-light txt-dark',
          placement: 'bottom',
          isOpen: basictooltip,
          target: 'LogOutLink',
          toggle: toggle
        }} >
        {LogOut}
      </ToolTip>
      <li onClick={Logout} id="LogOutLink" className='onhover-dropdown pe-0 py-0' >
        <span className='me-2'>{firstName} {lastName}</span>
        <LogIn />
      </li>
    </>
  )
};

export default UserHeader;
