export const MENUITEMS = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      { path: `${process.env.PUBLIC_URL}/dashboard`, icon: "home", type: "link", title: "Dashboard" },
      { path: `${process.env.PUBLIC_URL}/transactions`, icon: "", title: "Transactions", type: "link" },
      // { path: `${process.env.PUBLIC_URL}/requests`, icon: "", title: "Requests", type: "link" },
      { path: `${process.env.PUBLIC_URL}/clients`, icon: "", title: "Clients", type: "link" },
      
      // { path: `${process.env.PUBLIC_URL}/delivery`, icon: "job-search", type: "link", title: "Delivery" },
      // { path: `${process.env.PUBLIC_URL}/investments`, icon: "orders", type: "link", title: "Investments" },
    ],
  },

];
