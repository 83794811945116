import React, { useEffect, useState } from 'react';
import Context from './index';
import axios from 'axios';
import { AllProjectApi, AllLoadApi } from '../../api';

const LoadProvider = (props) => {
    const [allData, setAllData] = useState([]);

    const getAllLoadData = async () => {
        try {
            await axios.get(AllLoadApi, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setAllData(resp.data);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    return (
        <Context.Provider
            value={{
                ...props,
                getAllLoadData: getAllLoadData,
                allData,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default LoadProvider;