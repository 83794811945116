import React, { Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import { H6, H5, Image, P } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';

const ClientPersonalInfo = ({ data }) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl='4' className='mb-3'>
            <Card className='h-100 m-0'>
              <CardBody>
                <Image
                  attrImage={{
                    className: 'mb-3 rounded-circle',
                    src: `${data?.imageUrl ? data?.imageUrl : require('../../../assets/images/user/avatar.jpg')}`,
                    alt: '',
                    style: { width: '64px', height: '64px' }
                  }}
                />
                <H5 attrH5={{ className: 'f-w-600' }}>{data?.firstName} {data?.lastName}</H5>
                <a className='txt-info' href={`mailto:${data.email}`}>{data?.email}</a>
              </CardBody>
            </Card>
          </Col>
          <Col xl='4' className='mb-3'>
            <Card className='h-100 m-0'>
              <CardBody>
                <div className='info p-0'>
                  <div className='ttl-info text-start'>
                    <H6>
                      <i className='fa fa-phone me-2' style={{ fontSize: '20px' }}></i> 
                      <a href={`tel:${data.phone}`} className='txt-info'>{data.phone}</a>
                    </H6>
                  </div>
                  <div className='ttl-info text-start'>
                    <H6>
                      <i className='icofont icofont-bank me-2' style={{ fontSize: '20px' }}></i> {data.bankName}
                    </H6>
                  </div>

                  <div className='ttl-info text-start'>
                    <H6>
                      <i className='icofont icofont-credit-card me-2' style={{ fontSize: '20px' }}></i> {data.bankAccount}
                    </H6>
                  </div>

                  <div className='ttl-info text-start txt-primary'>
                    <H6>
                      <i className='icofont icofont-cur-dollar me-2' style={{ fontSize: '20px' }}></i> ֏{data.balanceMain}
                    </H6>
                  </div>
                  <div className='ttl-info text-start txt-success'>
                    <H6>
                      <i className='icofont icofont-money me-2' style={{ fontSize: '20px' }}></i> ֏{data.balanceAvailable}
                    </H6>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ClientPersonalInfo;