import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import TransactionsTable from './TransactionsTable';
import TransactionContext from '../../../_helper/Transactions';

const FinancesContain = () => {
    const { allData, getAllTransactionsData } = useContext(TransactionContext);
    useEffect(() => {
        getAllTransactionsData();
    }, []);

    return (
        <Fragment>
            <Breadcrumbs parent="Dashboard" title="Transactions" mainTitle="Transactions" />
            <Container fluid={true}>
                <Row>
                    {/* <Col sm="6">
                        <Balance />
                    </Col>
                    <Col sm="6">
                        <BalanceAvailable />
                    </Col> */}
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <TransactionsTable data={allData} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default FinancesContain;