import axios from 'axios';
import React, { useState } from 'react';
import { ClientsApi } from '../../api';
import Context from './index';

const ClientsProvider = (props) => {
    const [allData, setAllData] = useState(null);
    const [client, setClient] = useState(null);

    const getAllClientsData = async () => {
        setAllData(null);
        try {
            await axios.get(ClientsApi.getAll, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setAllData(resp.data);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    const getClientData = async (id) => {
        setClient(null);
        try {
            await axios.get(ClientsApi.get, {
                params: {
                    id: id,
                },
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setClient(resp.data);
            });
        } catch (error) {
            setClient({});
            console.log('cancelled', error);
        }
    };

    const renewClientData = async (id) => {
        try {
            await axios.get(ClientsApi.get, {
                params: {
                    id: id,
                },
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setClient(resp.data);
            });
        } catch (error) {
            setClient({});
            console.log('cancelled', error);
        }
    };

    return (
        <Context.Provider
            value={{
                ...props,
                getAllClientsData,
                getClientData,
                renewClientData,
                allData,
                client,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default ClientsProvider;