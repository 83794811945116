// MAIN
import DefaultDashboard from "../Components/Main/Dashboard";
import FinancesContain from "../Components/Main/Finances";

import ClientsContain from "../Components/Main/Clients";
import Client from "../Components/Main/Clients/Client";
import ErrorPage404 from '../Components/Main/ErrorPage404';

export const routes = [

  // MAIN
  { path: `${process.env.PUBLIC_URL}/dashboard/`, Component: <DefaultDashboard /> },
  { path: `${process.env.PUBLIC_URL}/transactions/`, Component: <FinancesContain /> },
  { path: `${process.env.PUBLIC_URL}/clients/`, Component: <ClientsContain /> },
  { path: `${process.env.PUBLIC_URL}/client/:id`, Component: <Client /> },

  // { path: `/invoice/:id`, Component: <Invoice /> },
  // { path: `${process.env.PUBLIC_URL}/delivery/`, Component: <Delivery /> },
  // { path: `${process.env.PUBLIC_URL}/investments/`, Component: <Investments /> },

  // { path: `${process.env.PUBLIC_URL}/load/:id`, Component: <LoadContain /> },
  // { path: `${process.env.PUBLIC_URL}/account/`, Component: <Account /> },
  { path: `*`, Component: <ErrorPage404 /> },
];
