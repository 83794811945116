
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import ClientsContext from '../../../_helper/Clients';
import TransactionsTable from '../Finances/TransactionsTable';
import Loading from '../Loading';
import TransactionContext from './../../../_helper/Transactions/index';
import ClientPersonalInfo from './ClientPersonalInfo';

const Client = () => {
  const [activeTab, setActiveTab] = useState('requests');

  let { id } = useParams();
  const { client, getClientData, renewClientData } = useContext(ClientsContext);
  const { clientTransactions, getClientTransactions } = useContext(TransactionContext);

  useEffect(() => {
    if (!!id) {
      getClientData(id);
      getClientTransactions(id);
    }
  }, [id]);

  return (
    <Fragment>
      <Breadcrumbs parent='Clents' title='Clients' mainTitle='Client' />
      <Container fluid={true}>
        <div>
          {
            client == null ?
              <Loading />
              :
              <Row>
                {
                  !!client.id ? <>
                    <Col xl='12' className=' box-col-6'>
                      <ClientPersonalInfo data={client} />
                    </Col>
                    <Col xl='12' className='box-col-8'>
                      <Card>
                        <CardBody>
                          <TransactionsTable data={clientTransactions} renewClientData={() => { renewClientData(id) }} />
                        </CardBody>
                      </Card>
                    </Col>
                  </> : <>
                    Client Not exists
                  </>
                }
              </Row>
          }

        </div>
      </Container>
    </Fragment>
  );
};
export default Client;
