import { H6 } from '../../../AbstractElements';

import React, { Fragment, useContext, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ClientsContext from '../../../_helper/Clients';
import { classname } from '../../../Constant';
import Loading from '../Loading';
const style2 = { padding: '4px 8px', fontSize: 14 };

const ClientsTable = ({ clientsData }) => {


  const tableColumns = [
    {
      name: 'Name',
      selector: (row) => (<div>
        <H6 attrH6={{ className: 'm-0' }}>{row.firstName} {row.lastName}</H6>
      </div>
      ),
      sortable: false,
      center: false,
      wrap: true,
      minWidth: '400px',
    },
    {
      name: 'Email',
      selector: (row) => (
        <a href={`mailto:${row.email}`}>{row.email}</a>
      ),
      sortable: false,
      center: false,
      wrap: true,
      minWidth: '200px',
      maxWidth: '400px',
    },
    {
      name: 'Phone',
      selector: (row) => (
        <a href={`tel:${row.phone}`}>{row.phone}</a>
      ),
      sortable: false,
      center: false,
      wrap: true,
      minWidth: '100px',
      maxWidth: '150px',
    },
    {
      name: 'Balance',
      selector: (row) => (<>֏{row.balanceMain}</>),
      conditionalCellStyles: [
        {
          when: (row) => true,
          classNames: ['txt-primary']
        }
      ],
      sortable: true,
      sortFunction: (a, b) => {
        return parseFloat(a.balanceMain) - parseFloat(b.balanceMain)
      },
      center: false,
      minWidth: '100px',
      maxWidth: '150px',
    },
    {
      name: 'Available',
      selector: (row) => (<>֏{row.balanceAvailable}</>),
      conditionalCellStyles: [
        {
          when: (row) => true,
          classNames: ['txt-success']
        }
      ],
      sortable: true,
      sortFunction: (a, b) => {
        return parseFloat(a.balanceAvailable) - parseFloat(b.balanceAvailable)
      },
      center: false,
      minWidth: '100px',
      maxWidth: '150px',
    },
    {
      name: 'Action',
      selector: (row) => (
        <div>
          <span>
            <Link to={'/client/' + row.id}>
              More
            </Link>
            {/* <Btn attrBtn={{ style: style2, color: 'info', className: 'btn btn-xs', type: 'button', onClick: () => { openModal(row) } }}>View</Btn> */}
          </span>
        </div>
      ),
      conditionalCellStyles: [
        {
          when: (row) => true,
          classNames: ['justify-content-center']
        }
      ],
      sortable: false,
      center: true,
      minWidth: '120px',
      maxWidth: '160px',
    },

  ]
  return (
    <Fragment>
      <div className='table-responsive product-table'>
        {null == clientsData ? <Loading />
          : <DataTable noHeader pagination columns={tableColumns} data={clientsData} highlightOnHover={true} striped={true} responsive={true} />}
      </div>
    </Fragment>
  );
};
export default ClientsTable;
