import React, { Fragment } from 'react';
import { Spinner } from '../../AbstractElements';


const Loading = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className='d-flex justify-content-center'>
                    <div className="loader-box" style={{ width: '85px', height: '85px' }}>
                        <Spinner attrSpinner={{ style: { width: '60px', height: '60px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Loading;