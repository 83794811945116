import React, { Fragment, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Btn, H6, Spinner } from '../../../AbstractElements';
import TransactionContext from '../../../_helper/Transactions';
import { toast } from "react-toastify";
import Loading from '../Loading';
const style2 = { width: 60, fontSize: 14, padding: 4 };

const TransactionsTable = ({ data, renewClientData }) => {
  const [modal, setModal] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const { setStatus } = useContext(TransactionContext);


  const openModal = (item) => {
    setModal(true);
    setModalItem(item);
  };

  const closeModal = (item) => {
    setModal(false);
    setModalItem(null);
  };


  const transactionsColumns = [
    {
      name: 'Details',
      selector: (row) => (<div>
        <H6>{row.title}</H6>
        <span>{row.description}</span>
      </div>
      ),
      sortable: false,
      center: false,
      wrap: true,
      minWidth: '400px',
    },
    {
      name: 'Type',
      selector: (row) => (<span className='text-capitalize'>{row.type}</span>),
      sortable: false,
      center: false,
      minWidth: '100px',
      maxWidth: '150px',
    },
    {
      name: 'Amount',
      selector: (row) => (<>֏{row.amount}</>),
      conditionalCellStyles: [
        {
          when: (row) => 'balance_refill' == row.type,
          classNames: ['txt-success']
        },
        {
          when: (row) => 'profit' == row.type,
          classNames: ['txt-success']
        },
        {
          when: (row) => 'withdraw' == row.type,
          classNames: ['txt-danger']
        },
        {
          when: (row) => 'contribution' == row.type,
          classNames: ['txt-danger']
        }
      ],
      sortable: true,
      sortFunction: (a, b) => {
        return parseFloat(a.amount) - parseFloat(b.amount)
      },
      center: false,
      minWidth: '100px',
      maxWidth: '150px',
    },
    {
      name: 'Date',
      selector: (row) => row.date,
      sortable: true,
      center: false,
      minWidth: '120px',
      maxWidth: '150px',
    },
    {
      name: 'Status',
      selector: (row) => (<span className='text-capitalize'>{row.status}</span>),
      conditionalCellStyles: [
        {
          when: (row) => 'paid' == row.status,
          classNames: ['txt-success']
        },
        {
          when: (row) => 'failed' == row.status,
          classNames: ['txt-danger']
        },
        {
          when: (row) => 'rejected' == row.status,
          classNames: ['txt-danger']
        },
        {
          when: (row) => 'pending' == row.status,
          classNames: ['txt-info']
        },
        {
          when: (row) => 'in_progress' == row.status,
          classNames: ['txt-warning']
        }
      ],
      sortable: false,
      center: false,
      minWidth: '120px',
      maxWidth: '150px',
    },
    {
      name: 'Action',
      selector: (row) => (
        <div>
          <span>
            <Btn attrBtn={{ style: style2, color: 'info', className: 'btn btn-xs', type: 'button', onClick: () => { openModal(row) } }}>View</Btn>
          </span>
        </div>
      ),
      sortable: false,
      center: false,
      minWidth: '160px',
      maxWidth: '160px',
    },
  ]

  const updateStatus = async (item, newStatus) => {
    setLoading(true);
    let result = await setStatus(item.id, item.client_id, newStatus);
    setLoading(false);
    if (true == result) {
      if (undefined != renewClientData) {
        renewClientData();
      }
      toast.success("Request status updated");
      closeModal();
    } else {
      toast.error('Something went wrong...');
    }
  }

  return (
    <Fragment>
      <div className='table-responsive product-table'>
        {null == data ? <Loading />
          :
          <DataTable noHeader pagination columns={transactionsColumns} data={data} highlightOnHover={true} striped={true} responsive={true} />
        }
      </div>
      <Modal isOpen={modal} toggle={closeModal} size='xl' centered>
        <ModalHeader toggle={closeModal}>
          {null == modalItem ? 'Transaction' :
            <>
              {modalItem?.title}
              <span className={`${'bank_to_balance' == modalItem.type ? 'txt-success' : 'txt-danger'}`}>{modalItem?.amount}</span>
            </>
          }
        </ModalHeader>
        <ModalBody>
          {null == modalItem ? 'Loading...' :
            <>
              <Card>
                <CardBody>
                  <div>
                    <H6>{modalItem.title}</H6>
                    <span>{modalItem.description}</span>
                  </div>
                  {'withdraw' == modalItem.type ?
                    <div style={{ gap: '8px' }} className='d-flex '>
                      {'pending' == modalItem.status ?
                        <>
                          <Btn attrBtn={{ color: 'danger', className: 'btn btn-lg', type: 'button', onClick: () => { updateStatus(modalItem, 'rejected') } }}>Reject</Btn>
                          <Btn attrBtn={{ color: 'warning', className: 'btn btn-lg', type: 'button', onClick: () => { updateStatus(modalItem, 'in_progress') } }}>In Progress</Btn>
                        </>
                        : ''
                      }
                      {'in_progress' == modalItem.status ?
                        <>
                          <Btn attrBtn={{ color: 'danger', className: 'btn btn-lg', type: 'button', onClick: () => { updateStatus(modalItem, 'rejected') } }}>Reject</Btn>
                          <Btn attrBtn={{ color: 'success', className: 'btn btn-lg', type: 'button', onClick: () => { updateStatus(modalItem, 'paid') } }}>Paid</Btn>
                        </>
                        : ''
                      }
                      {loading ?
                        <div className="loader-box" style={{ width: '40px', height: '40px' }}>
                          <Spinner attrSpinner={{ style: { width: '25px', height: '25px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                        </div>
                        : ''}
                    </div>
                    : ''}
                </CardBody>
              </Card>
            </>
          }
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default TransactionsTable;
