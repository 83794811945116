import React, { Fragment, useContext, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import ClientsContext from '../../../_helper/Clients';
import ClientsTable from './ClientsTable';

const ClientsContain = () => {
    const { allData, getAllClientsData } = useContext(ClientsContext);

    useEffect(() => {
        getAllClientsData();
    }, []);

    return (
        <Fragment>
            <Breadcrumbs parent="Clients" title="Clients" mainTitle="Clients" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <ClientsTable clientsData={allData} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default ClientsContain;